<template>
  <div class="account-wrap">
    <emb-page-title
      :heading="$t('message.partnerPage.header')"
    ></emb-page-title>
    <div class="inner-container" v-if="getUser != null">
      <div class="bg-white final-receipt-page section-gap">
        <div class="account-info">
          <div class="container grid-list-xl">
            <div class="layout justify-start mt-0 mb-4 mx-0">
              <div class="px-4">
                <v-card
                  class="mx-auto"
                  max-width="444"
                  outlined
                  v-for="(coupon, index) in coupons"
                  :key="index"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{ $t("message.partnerPage.promocode") }}:
                        <h3>{{ coupon.name.toUpperCase() }}</h3>
                      </div>
                      <v-list-item-title class="text-h6 mb-1">
                        {{ $t("message.partnerPage.referralStats") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-container>
                          <v-row no-gutters>
                            <v-col cols="8" md="2" lg="1" sm="2" xl="1">
                              {{ $t("message.partnerPage.yesterday") }}:
                            </v-col>
                            <v-col cols="auto" class="font-weight-black">
                              {{ coupon.count_yesterday }}</v-col
                            >
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="8" md="2" lg="1" sm="2" xl="1">
                              {{ $t("message.partnerPage.thisMonth") }}:
                            </v-col>
                            <v-col cols="auto" class="font-weight-black">
                              {{ coupon.count_this_month }}</v-col
                            >
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="8" md="2" lg="1" sm="2" xl="1">
                              {{ $t("message.partnerPage.lastMonth") }}:
                            </v-col>
                            <v-col cols="auto" class="font-weight-black">
                              {{ coupon.count_last_month }}</v-col
                            >
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="8" md="2" lg="1" sm="2" xl="1">
                              {{ $t("message.partnerPage.total") }}:
                            </v-col>
                            <v-col cols="auto" class="font-weight-black">
                              {{ coupon.count_total }}</v-col
                            >
                          </v-row>
                        </v-container>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar tile width="100" height="80">
                      <div class="mr-2">
                        {{ $t("message.partnerPage.today") }}:
                      </div>
                      <div>
                        <h4>{{ coupon.count_today }}</h4>
                      </div>
                    </v-list-item-avatar>
                  </v-list-item>
                  <!-- 
                  <v-card-actions>
                    <v-btn outlined rounded text> Button </v-btn>
                  </v-card-actions> -->
                </v-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
export default {
  computed: {
    ...mapGetters(["getUserInfo", "getUser", "isAdmin"]),
  },
  data() {
    return {
      coupons: [],
      coupon_listener: null,
    };
  },
  watch: {
    "$store.state.isAdmin": function () {
      if (this.coupon_listener != null) {
        this.coupon_listener();
        this.coupon_listener = null;
      }
      this.fetch_coupons();
    },
    "$store.state.isPartner": function () {
      this.fetch_coupons();
    },
  },
  mounted() {
    this.fetch_coupons();
  },
  unmounted() {
    if (this.coupon_listener != null) {
      this.coupon_listener();
      this.coupon_listener = null;
    }
  },

  methods: {
    fetch_coupons() {
      if (this.getUser != undefined) {
        if (this.coupon_listener != null) {
          this.coupon_listener();
          this.coupon_listener = null;
        }
        const couponCollectionRef = collection(getFirestore(), "coupons");
        var validCouponQuery = query(
          couponCollectionRef,
          where("valid", "==", true)
        );
        if (!this.isAdmin) {
          validCouponQuery = query(
            validCouponQuery,
            where("referral_user", "==", this.getUser.uid)
          );
        }

        this.coupon_listener = onSnapshot(validCouponQuery, (snapshot) => {
          var all_coupons = [];
          snapshot.forEach((doc) => {
            all_coupons.push(doc.data());
            console.log("Docs for each: ", doc.data());
          });
          this.coupons = all_coupons;
          this.$forceUpdate();
        });
      }
    },
  },
};
</script>
